import { Skeleton, Typography } from "@mui/material";
import ReactMarkdown from 'react-markdown'
import { IGeneralInfo } from "Models/GeneralInfo";

interface IProps {
    generalInfo: IGeneralInfo | undefined;
}

export default function Description(props: IProps) {
    return (
        <>
            {props.generalInfo?.descriptionHeader != null ?
                <Typography variant="h5"><ReactMarkdown components={{p: 'span'}}>{props.generalInfo.descriptionHeader}</ReactMarkdown></Typography> :
                <Typography variant="h5">{<Skeleton />}</Typography>
            }
            {props.generalInfo?.descriptionHeader != null ?
                <Typography variant="body1" textAlign='left' fontSize={14}><ReactMarkdown>{props.generalInfo.description}</ReactMarkdown></Typography> :
                <Typography variant="body1" textAlign='left' fontSize={14}>{<Skeleton height={180} />}</Typography>
            }
        </>
    );
}