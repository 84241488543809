import { KeyboardDoubleArrowDown } from "@mui/icons-material";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import Banners from "Components/Banners/Banners";
import AnalyticsHelper from "Helpers/FireStore/AnalyticsHelper";

export default function Contact() {
    AnalyticsHelper.LogScreenVisit("Contact");

    const iconProps: SxProps<Theme> = {
        height: 128,
        width: 128,
    }

    return (
        <Box pt={2}>
            <Typography variant='h3' mt={6} mb={3} fontWeight={200} fontFamily="'Atomicaboy', sans-serif" fontSize={{xs: 32, sm: 42, md: 48, lg: 56}} sx={{ wordWrap: 'break-word' }}>Contact & Openingstijden</Typography>
            <Box sx={iconProps} component={KeyboardDoubleArrowDown} />
            <Banners />
        </Box>
    );
}