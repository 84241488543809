import React, { useEffect } from "react";
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import { Alert, Box, Button, CircularProgress, Divider, FormControl, Grid, IconButton, InputLabel, Link, MenuItem, Select, Snackbar, Stack, TextField, Typography, } from "@mui/material";
import { IGeneralInfo } from "Models/GeneralInfo";
import FireStoreHelper from "Helpers/FireStore/FireStoreHelper";
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from "moment";
import { Delete, Remove } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers";

const daysOfWeek = [
    'Maandag',
    'Dinsdag',
    'Woensdag',
    'Donderdag',
    'Vrijdag',
    'Zaterdag',
    'Zondag',
]

export default function General() {
    const [isLoading, setIsLoading] = React.useState(true);
    const [generalInfo, setGeneralInfo] = React.useState<IGeneralInfo>();
    const [saveConfirmationIsOpen, setSaveConfirmationIsOpen] = React.useState(false);
    const [saveFailMessage, setSaveFailMessage] = React.useState<string>();

    firebase.auth();

    useEffect(() => {
        initialize();
    }, []);

    async function initialize() {
        var result = await FireStoreHelper.GetGeneralInfo();
        setGeneralInfo(result);
        setIsLoading(false);
    }

    async function saveChanges() {
        setSaveFailMessage(undefined);
        if (generalInfo == null)
            return;

        try {
            await FireStoreHelper.SetGeneralInfo(generalInfo);
        }
        catch (e) {
            console.log(e);
            setSaveFailMessage((e as any).stack);
        }

        setSaveConfirmationIsOpen(true);
    }

    function updateOpeningHour(index: number, value: moment.Moment | null) {
        if (value == null)
            return;

        let openingHoursClone = [...generalInfo?.openingHours ?? []];
        openingHoursClone[index] = value.format('HH:mm');
        setGeneralInfo({ ...generalInfo, openingHours: openingHoursClone } as IGeneralInfo);
    }

    function updateClosingHour(index: number, value: moment.Moment | null) {
        if (value == null)
            return;

        let closingHoursClone = [...generalInfo?.closingHours ?? []];
        closingHoursClone[index] = value.format('HH:mm');
        setGeneralInfo({ ...generalInfo, openingHours: closingHoursClone } as IGeneralInfo);
    }

    function onAddSpecialOpeningHourClick() {
        let specialOpeningHoursClone = [...generalInfo?.specialOpeningHours ?? []];
        specialOpeningHoursClone.push({ date: '', title: '', openingTime: '', closingTime: '' });
        setGeneralInfo({ ...generalInfo, specialOpeningHours: specialOpeningHoursClone } as IGeneralInfo);
    }

    function onUpdateSpecialOpeningHourTitle(index: number, title: string) {
        let specialOpeningHoursClone = [...generalInfo?.specialOpeningHours ?? []];
        specialOpeningHoursClone[index] = { ...specialOpeningHoursClone[index], title: title };
        setGeneralInfo({ ...generalInfo, specialOpeningHours: specialOpeningHoursClone } as IGeneralInfo);
    }

    function onUpdateSpecialOpeningHourDate(index: number, value: moment.Moment | null) {
        if (value == null)
            return;

        let specialOpeningHoursClone = [...generalInfo?.specialOpeningHours ?? []];
        specialOpeningHoursClone[index] = { ...specialOpeningHoursClone[index], date: value.format('DD:MM:YYYY') };
        setGeneralInfo({ ...generalInfo, specialOpeningHours: specialOpeningHoursClone } as IGeneralInfo);
    }

    function onUpdateSpecialOpeningHourOpeningTime(index: number, value: moment.Moment | null) {
        if (value == null)
            return;

        let specialOpeningHoursClone = [...generalInfo?.specialOpeningHours ?? []];
        specialOpeningHoursClone[index] = { ...specialOpeningHoursClone[index], openingTime: value.format('HH:mm') };
        setGeneralInfo({ ...generalInfo, specialOpeningHours: specialOpeningHoursClone } as IGeneralInfo);
    }

    function onUpdateSpecialOpeningHourClosingTime(index: number, value: moment.Moment | null) {
        if (value == null)
            return;

        let specialOpeningHoursClone = [...generalInfo?.specialOpeningHours ?? []];
        specialOpeningHoursClone[index] = { ...specialOpeningHoursClone[index], closingTime: value.format('HH:mm') };
        setGeneralInfo({ ...generalInfo, specialOpeningHours: specialOpeningHoursClone } as IGeneralInfo);
    }

    function onRemoveSpecialOpeningHour(index: number) {
        setGeneralInfo({ ...generalInfo, specialOpeningHours: generalInfo?.specialOpeningHours.filter((b, i) => i != index) } as IGeneralInfo);
    }

    function onUpdateBannerTitle(index: number, value: string) {
        let bannersClone = [...generalInfo?.banners ?? []];
        bannersClone[index] = { ...bannersClone[index], title: value };
        setGeneralInfo({ ...generalInfo, banners: bannersClone } as IGeneralInfo);
    }

    function onUpdateBannerMessage(index: number, value: string) {
        let bannersClone = [...generalInfo?.banners ?? []];
        bannersClone[index] = { ...bannersClone[index], message: value };
        setGeneralInfo({ ...generalInfo, banners: bannersClone } as IGeneralInfo);
    }

    function onUpdateBannerSeverity(index: number, value: number) {
        let bannersClone = [...generalInfo?.banners ?? []];
        bannersClone[index] = { ...bannersClone[index], severity: value };
        setGeneralInfo({ ...generalInfo, banners: bannersClone } as IGeneralInfo);
    }

    function onRemoveBannerClick(index: number) {
        setGeneralInfo({ ...generalInfo, banners: generalInfo?.banners.filter((b, i) => i != index) } as IGeneralInfo);
    }

    function onAddBannerClick() {
        let bannersClone = [...generalInfo?.banners ?? []];
        bannersClone.push({ title: '', message: '', severity: 0 });
        setGeneralInfo({ ...generalInfo, banners: bannersClone } as IGeneralInfo);
    }

    function renderInputs() {
        return (
            <Box p={{ xs: 1, sm: 2, lg: 4 }}>
                <Stack spacing={2}>
                    <TextField
                        label='Hoofdtitel'
                        helperText='De titel die op iedere pagina boven in de balk getoond wordt.'
                        value={generalInfo?.header}
                        onChange={e => setGeneralInfo({ ...generalInfo, header: e.target.value } as IGeneralInfo)}
                    />
                    <p>Voor meer geavanceerde text wordt gebruikt gemaakt van <Link target='_blank' href='https://www.markdownguide.org/basic-syntax/'>Markdown</Link></p>

                    <TextField
                        required
                        multiline
                        maxRows={2}
                        label='Titel'
                        value={generalInfo?.title}
                        onChange={e => setGeneralInfo({ ...generalInfo, title: e.target.value } as IGeneralInfo)}
                    />
                    <TextField
                        label='Ondertitel'
                        value={generalInfo?.subtitle}
                        onChange={e => setGeneralInfo({ ...generalInfo, subtitle: e.target.value } as IGeneralInfo)}
                    />
                    <TextField
                        label='Beschrijving titel'
                        value={generalInfo?.descriptionHeader}
                        onChange={e => setGeneralInfo({ ...generalInfo, descriptionHeader: e.target.value } as IGeneralInfo)}
                    />
                    <TextField
                        label="Beschrijving"
                        multiline
                        maxRows={8}
                        value={generalInfo?.description}
                        onChange={e => setGeneralInfo({ ...generalInfo, description: e.target.value } as IGeneralInfo)}
                    />
                    <Grid container spacing={2} p={0}>
                        <Grid item xs={12} md={6} lg={4} xl={3}>
                            <Stack spacing={2}>
                                <Typography sx={{ maxWidth: 600 }} variant='h5'>Openingstijden</Typography>
                                {daysOfWeek.map((d, i) => {
                                    return (
                                        <Box display='flex' alignItems='center' justifyContent='flex-start' key={d} >
                                            <Typography textAlign='start' sx={{ width: 120 }}>{d}</Typography>
                                            <TimePicker
                                                formatDensity='dense'
                                                ampm={false}
                                                value={moment(generalInfo?.openingHours[i], 'HH:mm')}
                                                onChange={e => updateOpeningHour(i, e)}
                                                sx={{ width: 120, mr: 1 }}
                                            />
                                            -
                                            <TimePicker
                                                formatDensity='dense'
                                                ampm={false}
                                                value={moment(generalInfo?.closingHours[i], 'HH:mm')}
                                                onChange={e => updateClosingHour(i, e)}
                                                sx={{ width: 120, ml: 1 }}
                                            />
                                        </Box>
                                    );
                                })}
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} xl={4} display='flex' flexDirection='column'>
                            <Typography sx={{ mb: 2 }} variant='h5'>Afwijkende openingstijden</Typography>
                            <Stack spacing={2} divider={<Divider flexItem />}>
                                <Typography>Zet beide tijden op 00:00 om "gesloten" aan te geven</Typography>
                                {generalInfo?.specialOpeningHours.map((d, i) => {
                                    return (
                                        <Stack spacing={1}>
                                            <Stack spacing={2} direction='row' useFlexGap flexWrap='wrap' key={d.date.toString() + i}>
                                                <FormControl>
                                                    <TextField
                                                        value={d.title}
                                                        title="Naam"
                                                        onChange={e => onUpdateSpecialOpeningHourTitle(i, e.target.value as string)}
                                                    />
                                                </FormControl>
                                                <IconButton size='medium' onClick={() => onRemoveSpecialOpeningHour(i)}><Delete fontSize='medium' /></IconButton>
                                            </Stack>
                                            <Box display='flex' alignItems='center' flexDirection='row' justifyContent='flex-start' >
                                                <DatePicker
                                                    value={moment(d.date, 'DD:MM:YYYY')}
                                                    onChange={e => onUpdateSpecialOpeningHourDate(i, e)}
                                                    sx={{ width: 150, mr: 1 }}
                                                />
                                                <TimePicker
                                                    formatDensity='dense'
                                                    ampm={false}
                                                    value={moment(d.openingTime, 'HH:mm')}
                                                    onChange={e => onUpdateSpecialOpeningHourOpeningTime(i, e)}
                                                    sx={{ width: 120, mr: 1 }}
                                                />
                                                -
                                                <TimePicker
                                                    formatDensity='dense'
                                                    ampm={false}
                                                    value={moment(d.closingTime, 'HH:mm')}
                                                    onChange={e => onUpdateSpecialOpeningHourClosingTime(i, e)}
                                                    sx={{ width: 120, ml: 1 }}
                                                />
                                            </Box>
                                        </Stack>
                                    );
                                })}
                                <Button variant='contained' onClick={onAddSpecialOpeningHourClick}>Tijd toevoegen</Button>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4} xl={3} display='flex' flexDirection='column'>
                            <Typography sx={{ mb: 2 }} variant='h5'>Meldingen</Typography>
                            <Stack spacing={2} divider={<Divider flexItem />}>
                                {generalInfo?.banners.map((b, i) => {
                                    return (
                                        <Stack spacing={2} direction='row' useFlexGap flexWrap='wrap'>
                                            <FormControl>
                                                <InputLabel id={`banner-type-${i}`}>Type</InputLabel>
                                                <Select
                                                    value={b.severity}
                                                    labelId={`banner-type-${i}`}
                                                    label="Type"
                                                    onChange={e => onUpdateBannerSeverity(i, e.target.value as number)}
                                                >
                                                    <MenuItem value={0} sx={{ backgroundColor: t => t.palette.success.light }}>Succes</MenuItem>
                                                    <MenuItem value={1} sx={{ backgroundColor: t => t.palette.info.light }}>Informatie</MenuItem>
                                                    <MenuItem value={2} sx={{ backgroundColor: t => t.palette.warning.light }}>Waarschuwing</MenuItem>
                                                    <MenuItem value={3} sx={{ backgroundColor: t => t.palette.error.light }}>Error</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <IconButton size='medium' onClick={() => onRemoveBannerClick(i)}><Delete fontSize='medium' /></IconButton>
                                            <TextField
                                                label="Titel"
                                                value={b.title}
                                                onChange={e => onUpdateBannerTitle(i, e.target.value)}
                                                fullWidth
                                            />
                                            <TextField
                                                fullWidth
                                                multiline
                                                maxRows={2}
                                                label="Bericht"
                                                value={b.message}
                                                onChange={e => onUpdateBannerMessage(i, e.target.value)}
                                            />
                                        </Stack>
                                    );
                                })}
                                <Button variant='contained' onClick={onAddBannerClick}>Melding toevoegen</Button>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Button onClick={saveChanges} variant='contained'>Opslaan</Button>
                </Stack>
            </Box>
        );
    }

    return (
        <Box p={2}>
            <Typography variant='h3' mt={3} mb={3} fontWeight={800} fontFamily="'Black Han Sans', sans-serif" fontSize={{ xs: 32, sm: 42, md: 48 }} sx={{ wordWrap: 'break-word' }}>Algemeen Beheer</Typography>

            {isLoading ? <CircularProgress /> : renderInputs()}

            <Snackbar open={saveConfirmationIsOpen} autoHideDuration={6000} onClose={() => setSaveConfirmationIsOpen(false)}>
                <Alert onClose={() => setSaveConfirmationIsOpen(false)} severity={saveFailMessage != null ? 'error' : 'success'} sx={{ width: '100%' }}>
                    {saveFailMessage ?? 'Aanpassingen succesvol opgeslagen'}
                </Alert>
            </Snackbar>
        </Box>
    );
}