import React, { useEffect } from "react";
import firebase from 'firebase/compat/app';
import "firebase/compat/storage";
import "firebase/compat/auth";
import FireStoreHelper from "Helpers/FireStore/FireStoreHelper";
import moment from "moment";
import { INewsMessage } from "Models/NewsMessage";
import { Alert, Box, Button, Link, Paper, Snackbar, Stack, TextField, Typography } from "@mui/material";
import NewsMessageSummary from "Components/NewsMessage/NewsMessageSummary";

export default function AddNews() {
    const [isSaving, setIsSaving] = React.useState(false);
    const [newMessageTitle, setNewMessageTitle] = React.useState('');
    const [newMessageMessage, setNewMessageMessage] = React.useState('');
    const [newMessagePhotoOneUrl, setNewMessagePhotoOneUrl] = React.useState('');
    const [newMessagePhotoOneFile, setNewMessagePhotoOneFile] = React.useState<FileList>();
    const [newMessagePhotoOneInput, setNewMessagePhotoOneInput] = React.useState<HTMLInputElement>();
    const [saveConfirmationIsOpen, setSaveConfirmationIsOpen] = React.useState(false);
    const [saveFailMessage, setSaveFailMessage] = React.useState<string>();
    const [storageRef, setStorageRef] = React.useState<firebase.storage.Reference>();

    firebase.auth();

    useEffect(() => {
        initialize();
    }, []);

    async function initialize() {
        var activeStorageRef = storageRef ?? firebase.storage().ref().child('news');

        if (storageRef === undefined) {
            setStorageRef(activeStorageRef);
        }
    }

    function choosePhoto(event: React.ChangeEvent<HTMLInputElement>) {
        let files = event.target.files ?? undefined;
        setNewMessagePhotoOneFile(files);
        setNewMessagePhotoOneInput(event.target);

        if (files == undefined)
            return;

        const reader = new FileReader();
        reader.onloadend = async (e) => {
            let fileString = reader.result as string;

            setNewMessagePhotoOneUrl(fileString);
        };
        reader.readAsDataURL(files[0]);
    }

    async function saveNewMessage() {
        setSaveFailMessage(undefined);
        setIsSaving(true);
        let newNewsMessage = getNewNewsMessage();

        if (newMessagePhotoOneFile == undefined) {
            try {
                await FireStoreHelper.AddNewsMessage({ ...newNewsMessage, imageOneUrl: undefined });
            } catch (e) {
                setSaveFailMessage((e as any).stack);
            }

            setSaveConfirmationIsOpen(true);
            setIsSaving(false);

            return;
        }

        const reader = new FileReader();
        let fileName = `news-${newNewsMessage.addedOn.format('YYYYMMDD')}_${(Math.floor(Math.random() * 1000))}.jpg`;
        let storageLocation = storageRef!.child(fileName);

        let metadata = {
            contentType: 'image/jpeg',
        };

        reader.onloadend = async (e) => {
            let fileString = reader.result as string;
            let uploadResult = await storageLocation.putString(fileString, 'data_url', metadata);
            
            try {
                await FireStoreHelper.AddNewsMessage({...newNewsMessage, imageOneUrl: await uploadResult.ref.getDownloadURL()});
            }
            catch (e) {
                console.log(e);
                setSaveFailMessage((e as any).stack);
            }

            setSaveConfirmationIsOpen(true);
            setIsSaving(false);
        };

        reader.readAsDataURL(newMessagePhotoOneFile[0]);
    }

    function getNewNewsMessage(): INewsMessage {
        return { id: undefined, addedOn: moment(), imageOneUrl: newMessagePhotoOneUrl, layout: 1, message: newMessageMessage, title: newMessageTitle }
    }

    return (
        <>
            <Box component={Paper} p={2}>
                <Typography variant='h4' mt={3} fontWeight={500} color={t => t.palette.grey[800]} fontFamily="'Black Han Sans', sans-serif" fontSize={{ xs: 24, sm: 32, md: 42 }} sx={{ wordWrap: 'break-word' }}>Nieuw bericht</Typography>
                <p>Voor meer geavanceerde text wordt gebruikt gemaakt van <Link target='_blank' href='https://www.markdownguide.org/basic-syntax/'>Markdown</Link></p>
                <Box p={{ xs: 1, sm: 2, lg: 4 }} pt={1}>
                    <Stack spacing={2}>
                        <TextField
                            label='Titel'
                            value={newMessageTitle}
                            onChange={e => setNewMessageTitle(e.target.value)}
                        />
                        <TextField
                            label="Bericht"
                            multiline
                            rows={4}
                            value={newMessageMessage}
                            onChange={e => setNewMessageMessage(e.target.value)}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box display='flex' flexDirection='column'>
                                <Button
                                    variant="contained"
                                    component="label"
                                >
                                    Foto toevoegen
                                    <input
                                        type="file"
                                        onChange={choosePhoto}
                                        hidden
                                    />
                                </Button>
                                <Typography variant='caption'>Voorbeeld is max. 200 pixels</Typography>
                            </Box>
                            {newMessagePhotoOneUrl.length > 0 && <img style={{ maxWidth: 200, maxHeight: 200, display: 'block', width: 'auto', height: 'auto', marginLeft: 16 }} src={newMessagePhotoOneUrl} />}
                        </Box>
                    </Stack>
                </Box>
            </Box>
            <Box width='100%' display='flex' justifyContent='center'>
                <Box py={2} display='block' width='100%' maxWidth={{ xs: 600, sm: 700, md: 800 }}>
                    <NewsMessageSummary newsMessage={getNewNewsMessage()} />
                </Box>
            </Box>
            <Button sx={{ mb: 2 }} disabled={isSaving} fullWidth variant='contained' onClick={saveNewMessage}>Bericht plaatsen</Button>
            <Snackbar open={saveConfirmationIsOpen} autoHideDuration={6000} onClose={() => setSaveConfirmationIsOpen(false)}>
                <Alert onClose={() => setSaveConfirmationIsOpen(false)} severity={saveFailMessage != null ? 'error' : 'success'} sx={{ width: '100%' }}>
                    {saveFailMessage ?? 'Nieuwsbericht succesvol geplaatst'}
                </Alert>
            </Snackbar>
        </>
    );
}