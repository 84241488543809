import { Box, Link, Skeleton, Stack, Typography } from "@mui/material";
import ReactMarkdown from 'react-markdown'
import React, { useEffect } from "react";
import firebase from 'firebase/compat/app';
import { INewsMessage } from "Models/NewsMessage";
import NewsMessageSummary from "Components/NewsMessage/NewsMessageSummary";
import FireStoreHelper from "Helpers/FireStore/FireStoreHelper";

export default function TopNews() {
    const [newsMessages, setNewsMessages] = React.useState<INewsMessage[]>();
    const [isLoading, setIsLoading] = React.useState(false);

    useEffect(() => {
        initialize();
    }, []);

    async function initialize() {
        var result = await FireStoreHelper.GetNewsMessages(10);
        setNewsMessages(result);
        setIsLoading(false);
    }

    return (
        <>
            <Typography variant='h4'fontWeight={500} color={t => t.palette.grey[900]} fontFamily="'Atomicaboy', sans-serif" fontSize={{ xs: 24, sm: 32, md: 42 }} sx={{ wordWrap: 'break-word' }}>Nieuws</Typography>
            <Stack spacing={2}>
                {newsMessages?.map(nm => {
                    return (
                        <NewsMessageSummary key={nm.addedOn.toString()} newsMessage={nm} /> 
                    );
                })}
            </Stack>
            <Box mt={1}><Link href="/nieuws"><Typography>Meer nieuws...</Typography></Link></Box>
        </>
    );
}