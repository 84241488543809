import { Box, Card, CardContent, CardMedia, Paper, Typography } from "@mui/material";
import { INewsMessage } from "Models/NewsMessage";
import ReactMarkdown from "react-markdown";

interface IProps {
    newsMessage: INewsMessage;
}

export default function NewsMessageSummary(props: IProps) {
    const { newsMessage } = props;

    return(
        <Card sx={{ display: 'flex' }}>
            {newsMessage.imageOneUrl && <CardMedia
              component="img"
              sx={{ maxWidth: { xs: 100, sm: 150, md: 180, lg: 200 }, maxHeight: { xs: 140, sm: 150, md: 180, lg: 200 }, display: 'block', width: 'auto', height: 'auto' }}
              image={newsMessage.imageOneUrl}
              alt={newsMessage.title + " afbeelding"}
            />}
            <Box display='flex' alignItems='flex-start' flexDirection='column' width='100%'>
                <CardContent sx={{ width: { xs: '93%', sm: '95%', md: '92%'}, p: { xs: 1, sm: 2 }, height: '100%', position: 'relative'}}>
                    <Box position='relative'>
                        <Typography variant="h4" fontWeight={500} fontSize={{ xs: 18, sm: 24, md: 28, lg: 30 }} fontFamily="'Black Han Sans', sans-serif" component="div" textAlign='start'>{newsMessage.title}</Typography>
                        
                    </Box>
                    <Typography variant="body2" textAlign='start' sx={{ width: "100%", height: { xs: 60, sm: 80, md: 100 }, maxHeight: { xs: 60, sm: 80, md: 100 }}} fontSize={{ xs: 12, sm: 14, md: 16 }} mt={{xs: '-10px', sm: -2, lg: 1}}>
                        <ReactMarkdown>{newsMessage.message}</ReactMarkdown>
                    </Typography>
                    <Typography sx={{ fontSize: {xs: 8, md: 14} }} color="text.secondary"position='absolute' bottom={{xs: 0, md: 2, lg: -2}} right={{xs : 2, sm: 12, md: 6, lg: 1}}>{newsMessage.addedOn.format('L')}</Typography>
                </CardContent>
            </Box>
        </Card>
    );
}