import { IGeneralInfo } from "Models/GeneralInfo";
import firebase from "firebase/compat/app";
import { aboutDataConverter, generalInfoConverter, newsMessageConverter } from "./Converters";
import { INewsMessage } from "Models/NewsMessage";
import { IAboutData } from "Models/AboutData";

export default class FireStoreHelper {
    public static fireStore: firebase.firestore.Firestore | undefined = undefined;
    private static cachedGeneralInfo: IGeneralInfo | undefined = undefined;
    private static cachedAboutData: IAboutData | undefined = undefined;
    
    public static SetFireStore(givenFireStore: firebase.firestore.Firestore){
        FireStoreHelper.fireStore = givenFireStore;
    }
    
    public static async GetGeneralInfo(): Promise<IGeneralInfo> {
        if (FireStoreHelper.cachedGeneralInfo != undefined)
            return FireStoreHelper.cachedGeneralInfo

        let doc = await this.fireStore?.collection('info').doc('general').withConverter(generalInfoConverter).get();
        var data = doc?.data();
        if (data == null)
            return { header: '', title: '', description: '', descriptionHeader: '', subtitle: '', specialOpeningHours: [], openingHours: [], closingHours: [], banners: [] };

        FireStoreHelper.cachedGeneralInfo = data;
        return data;
    }

    public static async SetGeneralInfo(generalInfo: IGeneralInfo) {
        await this.fireStore?.collection('info').doc('general').withConverter(generalInfoConverter).update(generalInfo);
        FireStoreHelper.cachedGeneralInfo = undefined;
    }

    public static async GetAboutData(): Promise<IAboutData> {
        if (FireStoreHelper.cachedAboutData != undefined)
            return FireStoreHelper.cachedAboutData

        let doc = await this.fireStore?.collection('info').doc('about').withConverter(aboutDataConverter).get();
        var data = doc?.data();
        if (data == null)
            return { content: 'Oeps, is er is iets fout gegaan bij het ophalen van deze informatie. Je kan niet altijd 6 gooien...' };

        FireStoreHelper.cachedAboutData = data;
        return data;
    }

    public static async SetAboutData(aboutData: IAboutData) {
        await this.fireStore?.collection('info').doc('about').withConverter(aboutDataConverter).update(aboutData);
        FireStoreHelper.cachedAboutData = undefined;
    }

    public static async GetNewsMessages(limit?: number): Promise<INewsMessage[]> {
        let getNewsMessageQuery = this.fireStore?.collection('news').orderBy('addedOn', 'desc');
        if (limit != undefined)
            getNewsMessageQuery = getNewsMessageQuery?.limit(limit!);

        let doc = await getNewsMessageQuery?.withConverter(newsMessageConverter).get();
        
        if (doc == null || doc.empty)
            return [];

        return doc.docs.map(d => d.data());
    }

    public static async AddNewsMessage(newsMessage: INewsMessage) {
        await this.fireStore?.collection('news').withConverter(newsMessageConverter).add(newsMessage);
    }

    public static async DeleteNewsMessage(newsMessageId: string) {
        await this.fireStore?.collection('news').doc(newsMessageId).delete();
    }
}