import "firebase/compat/storage";
import "firebase/compat/auth";
import { Box, Divider, Typography } from "@mui/material";
import AddNews from "./Components/AddNews";
import ManageNews from "./Components/ManageNews";

export default function News() {
    return (
        <Box p={2} bgcolor='rgb(243, 246, 249)'>
            <Typography variant='h3' mt={3} mb={3} fontWeight={800} fontFamily="'Black Han Sans', sans-serif" fontSize={{ xs: 32, sm: 42, md: 48 }} sx={{ wordWrap: 'break-word' }}>Nieuwsberichten beheer</Typography>

            <AddNews />
            <Divider />
            <Typography variant='h4' mt={3} mb={3} fontWeight={500} color={t => t.palette.grey[800]} fontFamily="'Black Han Sans', sans-serif" fontSize={{ xs: 24, sm: 32, md: 42 }} sx={{ wordWrap: 'break-word' }}>Bestaande berichten</Typography>

            <ManageNews />
        </Box>
    );
}