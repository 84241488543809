import { Alert, AlertTitle, Stack, AlertColor } from "@mui/material";
import React, { useEffect } from "react";
import { IBanner } from "Models/GeneralInfo";
import FireStoreHelper from "Helpers/FireStore/FireStoreHelper";

const severities = [
    'success',
    'info',
    'warning',
    'error'
]

export default function Banners() {
    const [banners, setBanners] = React.useState<IBanner[]>([]);

    useEffect(() => {
        initialize();
    }, []);

    async function initialize() {
        var result = await FireStoreHelper.GetGeneralInfo();
        setBanners(result?.banners ?? []);
    }

    return(
        <Stack spacing={2} p={4} display='flex' alignItems='center'>
            {banners.map(b => {
                return (
                    <Alert severity={severities[b.severity] as AlertColor}>
                        {b.title && b.title.length > 0 && <AlertTitle>{b.title}</AlertTitle>}
                        <strong>{b.message}</strong>
                    </Alert>
                );
            })}
        </Stack>
    );
}