import React, { useEffect } from "react";
import ReactMarkdown from 'react-markdown'
import { Box, Container, Grid, Skeleton, Typography } from "@mui/material";
import logo from 'Images/logo_image.png';
import Description from "./Components/Description";
import { IGeneralInfo } from "Models/GeneralInfo";
import FireStoreHelper from "Helpers/FireStore/FireStoreHelper";
import Banners from "Components/Banners/Banners";
import TopNews from "./Components/TopNews";
import AnalyticsHelper from "Helpers/FireStore/AnalyticsHelper";

export default function Home() {
    AnalyticsHelper.LogScreenVisit("Home");
    const [isLoading, setIsLoading] = React.useState(true);
    const [generalInfo, setGeneralInfo] = React.useState<IGeneralInfo>();

    useEffect(() => {
        initialize();
    }, []);

    async function initialize() {
        var result = await FireStoreHelper.GetGeneralInfo();
        setGeneralInfo(result);
        setIsLoading(false);
    }

    return (
        <Box bgcolor={theme => theme.palette.grey[100]}>
            <Box
                flexDirection={{ xs: 'column-reverse', md: 'row' }}
                paddingX={{xs: 4, md: 0}}
                paddingBottom={{xs: 4, md: 10}}
                paddingTop={{xs: 10, md: 12}}
                sx={{
                    backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)), url("https://firebasestorage.googleapis.com/v0/b/winkel-de-lier.appspot.com/o/main%2FKeer_Keer_1080_GP.jpg?alt=media&token=0b65761f-aef6-4a17-99a9-21ee0ddca3fa")',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'relative',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                }}>
                <Box mr={{ xs: 0, md: 8 }} sx={{ color: 'white' }} textAlign={{ xs: 'center', md: 'start' }}>
                    {generalInfo?.title != null ?
                        <Typography variant="h2" fontFamily="'Atomicaboy', sans-serif"><ReactMarkdown components={{p: 'span'}}>{generalInfo.title}</ReactMarkdown></Typography> :
                        <Typography variant="h2" fontFamily="'Atomicaboy', sans-serif"><Skeleton width={500} /></Typography>
                    }
                    {generalInfo?.subtitle != null ?
                        <Typography><ReactMarkdown components={{p: 'span'}}>{generalInfo.subtitle}</ReactMarkdown></Typography> :
                        <Typography><Skeleton width={350} /></Typography>
                    }
                </Box>
                <Box
                    mb={{xs: 4, md: 0}}
                    height={{xs: 150, md: 200}}
                    component='img'
                    src={logo} />
            </Box>
            <Container maxWidth='xl' sx={{ minHeight: '60vh' }}>
                <Banners />
                <Grid container spacing={{xs: 2, lg: 4}} pb={2} justifyContent='center'>
                    <Grid item xs={12} md={4} lg={4}>
                        <Description generalInfo={generalInfo} />
                    </Grid>
                    <Grid item xs={12} md={8} lg={6}>
                        <TopNews />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}