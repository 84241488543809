import React, { useEffect } from "react";
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import { Alert, Box, Button, CircularProgress, Link, Skeleton, Snackbar, Stack, TextField, Typography, } from "@mui/material";
import { IAboutData } from "Models/AboutData";
import FireStoreHelper from "Helpers/FireStore/FireStoreHelper";
import ReactMarkdown from "react-markdown";

export default function General() {
    const [isLoading, setIsLoading] = React.useState(true);
    const [aboutData, setAboutData] = React.useState<IAboutData>();
    const [saveConfirmationIsOpen, setSaveConfirmationIsOpen] = React.useState(false);
    const [saveFailMessage, setSaveFailMessage] = React.useState<string>();

    firebase.auth();

    useEffect(() => {
        initialize();
    }, []);

    async function initialize() {
        var result = await FireStoreHelper.GetAboutData();
        setAboutData(result);
        setIsLoading(false);
    }

    async function saveChanges() {
        setSaveFailMessage(undefined);
        if (aboutData == null)
            return;
        
        try
        {
            await FireStoreHelper.SetAboutData(aboutData);
        }
        catch(e) {
            console.log(e);
            setSaveFailMessage((e as any).stack);
        }

        setSaveConfirmationIsOpen(true);
    }

    function renderInputs()
    {
        return(
            <div>
                <Box p={{ xs: 1, sm: 2, lg: 4 }}>
                    <Stack spacing={2}>
                        <p>Voor geavanceerde text wordt gebruikt gemaakt van <Link target='_blank' href='https://www.markdownguide.org/basic-syntax/'>Markdown</Link></p>
                        <TextField
                            fullWidth
                            multiline
                            label='Inhoud'
                            value={aboutData?.content}
                            onChange={e => setAboutData({...aboutData, content:  e.target.value} as IAboutData)}
                        />
                        <Button onClick={saveChanges} variant='contained'>Opslaan</Button>
                        <div>
                        <Typography variant='h3' mt={6} mb={3} fontWeight={800} fontFamily="'Black Han Sans', sans-serif" fontSize={{xs: 32, sm: 42, md: 48}} sx={{ wordWrap: 'break-word' }}>Over ons</Typography>
                            {isLoading ?
                                <Typography><Skeleton width='90%' /></Typography> :
                                <Typography align="left"><ReactMarkdown components={{p: 'span'}}>{aboutData!.content!}</ReactMarkdown></Typography>}
                        </div>
                    </Stack>
                </Box>
            </div>);
    };

    return (
        <Box p={2}>
            <Typography variant='h3' mt={3} mb={3} fontWeight={800} fontFamily="'Black Han Sans', sans-serif" fontSize={{xs: 32, sm: 42, md: 48}} sx={{ wordWrap: 'break-word' }}>Algemeen Beheer</Typography>

            {isLoading ? <CircularProgress /> : renderInputs()}

            <Snackbar open={saveConfirmationIsOpen} autoHideDuration={6000} onClose={() => setSaveConfirmationIsOpen(false)}>
              <Alert onClose={() => setSaveConfirmationIsOpen(false)} severity={saveFailMessage != null ? 'error' : 'success'} sx={{ width: '100%' }}>
                {saveFailMessage ?? 'Aanpassingen succesvol opgeslagen'}
              </Alert>
            </Snackbar>
        </Box>
    );
}