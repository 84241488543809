import './App.css';
import 'firebase/compat/analytics'
import "firebase/compat/firestore";
import firebase from "firebase/compat/app";
import { ThemeProvider } from '@mui/material';
import { ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import Frame from './Scenes/Frame';
import FireStoreHelper from 'Helpers/FireStore/FireStoreHelper';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import moment from 'moment';
import 'moment/locale/nl';
import AnalyticsHelper from 'Helpers/FireStore/AnalyticsHelper';

const firebaseConfig = {
  apiKey: "AIzaSyDcmvPL8QPuRQ0XAopy3c5lppdW19Y4vcY",
  authDomain: "winkel-de-lier.firebaseapp.com",
  projectId: "winkel-de-lier",
  storageBucket: "winkel-de-lier.appspot.com",
  messagingSenderId: "927110802281",
  appId: "1:927110802281:web:94ddfb8e5dea5ea3a96bf8",
  measurementId: "G-H7QSDKPN60"
};

const themeOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#589B31',
    },
    secondary: {
      main: '#7936a0',
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
  },
};

const theme = createTheme(themeOptions);

function App() {
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);

    if (!isDev) {
      AnalyticsHelper.SetAnalytics(firebase.analytics());
    }
  }

  moment.locale('nl');
  FireStoreHelper.SetFireStore(firebase.firestore());

  return (
    <div className="App" style={{ minHeight: '100%', overflow: 'auto', backgroundColor: theme.palette.primary.main }}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Frame />
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
