import React, { useEffect } from "react";
import { Box, Container, Skeleton, Typography } from "@mui/material";
import { IAboutData } from "Models/AboutData";
import FireStoreHelper from "Helpers/FireStore/FireStoreHelper";
import ReactMarkdown from "react-markdown";
import AnalyticsHelper from "Helpers/FireStore/AnalyticsHelper";

export default function About() {
    AnalyticsHelper.LogScreenVisit("About");
    const [isLoading, setIsLoading] = React.useState(true);
    const [aboutData, setAboutData] = React.useState<IAboutData>();

    useEffect(() => {
        initialize();
    }, []);

    async function initialize() {
        var result = await FireStoreHelper.GetAboutData();
        setAboutData(result);
        setIsLoading(false);
    }

    return (
        <Box pt={2}>
            <Container maxWidth='xl'>
            <Typography variant='h3' mt={6} mb={3} fontWeight={800} fontFamily="'Black Han Sans', sans-serif" fontSize={{xs: 32, sm: 42, md: 48}} sx={{ wordWrap: 'break-word' }}>Over ons</Typography>
            {isLoading ?
                <Typography><Skeleton width='90%' /></Typography> :
                <Typography align="left"><ReactMarkdown components={{p: 'span'}}>{aboutData!.content!}</ReactMarkdown></Typography>}
            </Container>
        </Box>
    );
}