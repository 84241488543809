import React from 'react';
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";

export const adminUserUids = ['H4qh9z9lJ2MlN1katPfIO5qQkXK2', 'wCaH3DwZ3UgbqGGaxcP6w7HMOy32', 'lC65dR1kwzgNOVJnrecoGPyoYm22'];

export default function Admin() {
    const [fireUser, setFireUser] = React.useState<firebase.User>();

    firebase.auth().onAuthStateChanged(function (newUser) {
        if (newUser) {
          setFireUser(newUser);
        } else {
          setFireUser(undefined);
        }
    });

    async function SignIn() {
        var provider = new firebase.auth.GoogleAuthProvider();
        await firebase.auth().signInWithPopup(provider);
        
        window.location.reload();
    }

    async function SignOut() {
        await firebase.auth().signOut();
        
        window.location.reload();
    }

    if (fireUser == null) {
        return (
            <Box py={2} bgcolor={theme => theme.palette.grey[100]}>
                <Button variant='contained' onClick={SignIn}>Inloggen</Button>
            </Box>
        );
    }

    if (!adminUserUids.includes(fireUser.uid))
    {
        return(
            <Box py={2} bgcolor={theme => theme.palette.grey[100]}>
                <Typography mb={1}>Geen admin gebruiker</Typography>
                <Button variant='outlined' onClick={SignOut}>Uitloggen</Button>
            </Box>
        )
    }

    return (
        <Box pt={2} bgcolor={theme => theme.palette.grey[100]}>
            <Typography variant='h3' mt={{ xs: 2, sm: 3, md: 4}} mb={3} fontWeight={800} fontFamily="'Black Han Sans', sans-serif" fontSize={{xs: 32, sm: 42, md: 48}} sx={{ wordWrap: 'break-word' }}>Beheer</Typography>
            <Grid container spacing={2} px={2} pt={1} pb={4}>
                <Grid item>
                    <Card>
                        <CardContent>
                            <Typography variant='h4' gutterBottom>
                              Algemene informatie bewerken
                            </Typography>
                            <Typography variant="body2" component="p" mb={1}>
                              Beheer teksten, contactinformatie en openingstijden
                            </Typography>
                            <div>
                              <Button variant="contained" href="/beheer/algemeen">Beheer</Button>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item>
                    <Card>
                        <CardContent>
                            <Typography variant='h4' gutterBottom>
                              Nieuwsberichten
                            </Typography>
                            <Typography variant="body2" component="p" mb={1}>
                              Plaats een nieuwsbericht of bewerk/verwijder bestaande
                            </Typography>
                            <div>
                              <Button variant="contained" href="/beheer/nieuws">Beheer</Button>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item>
                    <Card>
                        <CardContent>
                            <Typography variant='h4' gutterBottom>
                              Over Ons
                            </Typography>
                            <Typography variant="body2" component="p" mb={1}>
                              Pas de tekst op de "Over ons" pagina aan
                            </Typography>
                            <div>
                              <Button variant="contained" href="/beheer/over">Beheer</Button>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}