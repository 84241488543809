import {
  BrowserRouter,
    Route,
    Routes,
  } from "react-router-dom";
import Home from "./Home/Home";
import NotFound from "./Error/NotFound";
import Menu from "Components/Menu/Menu";
import Contact from "./Contact/Contact";
import { Box } from "@mui/material";
import React from "react";
import Footer from "Components/Footer/Footer";
import Admin from "./Admin/Admin";
import GeneralAdmin from "./Admin/General/General";
import NewsAdmin from "./Admin/News/News";
import AboutAdmin from "./Admin/About/About";
import News from "./News/News";
import About from "./About/About";

export default function Frame() {
  const [scrolledMenuTrigger, setScrolledMenuTrigger] = React.useState(false);

  // function onScroll(event: React.UIEvent<HTMLDivElement, UIEvent>) {
  //   setScrolledMenuTrigger(event.currentTarget.scrollTop > 300);
  // }

  window.onscroll = (_) => {
    setScrolledMenuTrigger(window.scrollY > 320);
  }
  
  let isOnMain = window.location.pathname == '/';

  return (
    <Box maxHeight='100%' sx={{backgroundColor: theme => theme.palette.primary.main}}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Menu IsHomeTop={!scrolledMenuTrigger} />} />
          <Route path="*" element={<Menu IsHomeTop={false} />} />
        </Routes>
      </BrowserRouter>
      <Box my={{ xs: isOnMain ? 0 : 7, sm: isOnMain ? 0 : 8 }} sx={{ backgroundColor: 'white' }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="contact-openingstijden" element={<Contact />} />
            <Route path="nieuws" element={<News />} />
            <Route path="over" element={<About />} />
            <Route path="beheer" element={<Admin />} />
            <Route path="beheer/algemeen" element={<GeneralAdmin />} />
            <Route path="beheer/nieuws" element={<NewsAdmin />} />
            <Route path="beheer/over" element={<AboutAdmin />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </Box>
      <Footer />
    </Box>
  );
}