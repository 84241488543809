import React, { useEffect } from "react";
import firebase from 'firebase/compat/app';
import { Alert, Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar, Stack, Tooltip } from "@mui/material";
import { INewsMessage } from "Models/NewsMessage";
import FireStoreHelper from "Helpers/FireStore/FireStoreHelper";
import NewsMessageSummary from "Components/NewsMessage/NewsMessageSummary";
import { Delete, Edit, Star } from "@mui/icons-material";

export default function ManageNews() {
    const [newsMessages, setNewsMessages] = React.useState<INewsMessage[]>();
    const [isLoading, setIsLoading] = React.useState(false);
    const [deleteConfirmationDialogIsOpenForNewsMessageId, setDeleteConfirmationDialogIsOpenForNewsMessageId] = React.useState<string>();
    const [deleteConfirmationMessageIsOpen, setDeleteConfirmationMessageIsOpen] = React.useState(false);
    const [deleteFailMessage, setDeleteFailMessage] = React.useState<string>();

    async function refreshNewsMessages() {
        setIsLoading(true);
        var result = await FireStoreHelper.GetNewsMessages(undefined);
        setNewsMessages(result);
        setIsLoading(false);
    }
    
    async function deleteNewsMessage(newsMessageId: string | undefined) {
        if (newsMessageId == undefined)
            return;

        try{
            await FireStoreHelper.DeleteNewsMessage(newsMessageId);
        }catch(e) {
            setDeleteFailMessage((e as any).stack);
        }

        setDeleteConfirmationMessageIsOpen(true);
        setDeleteConfirmationDialogIsOpenForNewsMessageId(undefined);

        await refreshNewsMessages();
    }

    return(
        <>
            {newsMessages == undefined && <Button sx={{ mt: 1 }} variant='contained' onClick={refreshNewsMessages} disabled={isLoading}>Toon nieuwsberichten</Button>}
            <Stack spacing={2} sx={{ display: 'flex', alignItems: 'center' }}>
                {newsMessages?.map(nm => {
                    return (
                        <Box width='100%' display='flex' justifyContent='center' alignItems='start'>
                            <Box width='100%' maxWidth={{ xs: 600, sm: 700, md: 800 }} mr={1}>
                                <NewsMessageSummary key={nm.addedOn.toString()} newsMessage={nm} /> 
                            </Box>
                            <ButtonGroup orientation='vertical'>
                                <Tooltip title='Op startpagina'><div><IconButton  disabled><Star /></IconButton></div></Tooltip>
                                <Tooltip title='Bewerken'><div><IconButton  disabled><Edit /></IconButton></div></Tooltip>
                                <Tooltip title='Verwijderen'><div><IconButton onClick={() => setDeleteConfirmationDialogIsOpenForNewsMessageId(nm.id)} ><Delete /></IconButton></div></Tooltip>
                            </ButtonGroup>
                        </Box>
                    );
                })}
            </Stack>
            <Dialog
              open={deleteConfirmationDialogIsOpenForNewsMessageId != undefined}
              onClose={() => setDeleteConfirmationDialogIsOpenForNewsMessageId(undefined)}
            >
              <DialogTitle id="alert-dialog-title">
                {"Wil je dit nieuwsbericht verwijderen?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Je staat op het punt om het nieuwsbericht '{newsMessages?.find(nm => nm.id == deleteConfirmationDialogIsOpenForNewsMessageId)?.title}' te verwijderen. Weet je dit zeker? Dit kan niet ongedaan worden gemaakt.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setDeleteConfirmationDialogIsOpenForNewsMessageId(undefined)}>Annuleren</Button>
                <Button onClick={() => deleteNewsMessage(deleteConfirmationDialogIsOpenForNewsMessageId)} autoFocus>
                  Verwijderen
                </Button>
              </DialogActions>
            </Dialog>
            <Snackbar open={deleteConfirmationMessageIsOpen} autoHideDuration={6000} onClose={() => setDeleteConfirmationMessageIsOpen(false)}>
                <Alert onClose={() => setDeleteConfirmationMessageIsOpen(false)} severity={deleteFailMessage != null ? 'error' : 'success'} sx={{ width: '100%' }}>
                    {deleteFailMessage ?? 'Nieuwsbericht succesvol verwijderd'}
                </Alert>
            </Snackbar>
        </>
    );
}