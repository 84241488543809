import { Box, Stack, Typography } from "@mui/material";
import NewsMessageSummary from "Components/NewsMessage/NewsMessageSummary";
import AnalyticsHelper from "Helpers/FireStore/AnalyticsHelper";
import FireStoreHelper from "Helpers/FireStore/FireStoreHelper";
import { INewsMessage } from "Models/NewsMessage";
import React from "react";
import { useEffect } from "react";

export default function News() {
    AnalyticsHelper.LogScreenVisit("News");
    
    const [isLoading, setIsLoading] = React.useState(true);
    const [newsMessages, setNewsMessages] = React.useState<INewsMessage[]>([]);

    useEffect(() => {
        initialize();
    }, []);

    async function initialize() {
        var result = await FireStoreHelper.GetNewsMessages(3);
        setNewsMessages(result);
        setIsLoading(false);
    }
    return (
        <Box pt={2} bgcolor='rgb(243, 246, 249)'>
            <Typography variant='h3' mt={{xs: 2, sm: 4, md: 6}} mb={{xs: 0, sm: 1 }} fontWeight={800} fontFamily="'Atomicaboy', sans-serif" fontSize={{xs: 32, sm: 42, md: 48, lg: 56}} sx={{ wordWrap: 'break-word' }}>Nieuws</Typography>
            <Box p={{xs: 1, sm: 2, md: 4}} display='flex' alignItems='center' justifyContent='center'>
                <Stack width='100%' spacing={4} maxWidth={{ xs: 600, sm: 700, md: 800 }}>
                    {newsMessages.map(nm => {
                        return <NewsMessageSummary key={nm.addedOn.calendar()} newsMessage={nm} />;
                    })}
                </Stack>
            </Box>
        </Box>
    );
}